import BaseScene from '../BaseScene';
import _, { first } from 'lodash';
import { TextButton } from '@/games/lib/text-button';

export default class PlayScene extends BaseScene {
  constructor () {
    super({ key: 'PlayScene' })
  }

  preload () {
    this.load.image('bg', '/static/games/game2/bg.jpg');
    this.load.audio('bg_audio', '/static/games/game2/bg.mp3');

    this.load.audio('win', '/static/games/game2/win.mp3');
    this.load.audio('correct', '/static/games/game2/correct.mp3');
    this.load.audio('correct_shout', '/static/games/game2/correct_shout.mp3');
    this.load.audio('wrong', '/static/games/game2/wrong.mp3');
    this.load.audio('wrong_shout', '/static/games/game2/wrong_shout.mp3');

    this.load.image('header_bg', '/static/games/game2/black.png');
    this.load.image('bar_bg', '/static/games/game2/time1.png');
    this.load.image('clock', '/static/games/game2/clock.png');


    this.load.spritesheet('bang_hit', '/static/games/game2/bang_hit.png',{ frameWidth: 144, frameHeight: 144 });
    this.load.spritesheet('bang_miss', '/static/games/game2/bang_miss.png',{ frameWidth: 144, frameHeight: 144 });

    this.load.spritesheet('char', '/static/games/game2/char.png',{ frameWidth: 188, frameHeight: 260 });

    this.load.svg('fullscreen', '/static/games/fullscreen.svg', {width:80, height:80});
    this.loadingProgress();
  }
  
  create () {
    const bg = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg')
    this.bg_audio = this.sound.add('bg_audio', {volume: 0.2});
    this.bg_audio.setLoop(true).play();

    this.audio_correct = this.sound.add('correct').setLoop(false);
    this.audio_correct_shout = this.sound.add('correct_shout').setLoop(false);
    this.audio_wrong = this.sound.add('wrong').setLoop(false);
    this.audio_wrong_shout = this.sound.add('wrong_shout').setLoop(false);
    this.audio_win = this.sound.add('win').setLoop(false);

    const header_bg = this.add.image(0, 0, 'header_bg').setOrigin(0)
    const bar_bg = this.add.image(45, 45, 'bar_bg').setOrigin(0)
    this.bar = this.add.graphics();
    
    //const bar = this.add.image(45, 45, 'bar').setOrigin(0)
    const clock = this.add.image(1010, -7, 'clock').setOrigin(0)
    
    const fullscreen = this.add.image(this.cameras.main.width-70, 70, 'fullscreen')
    fullscreen.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
      this.scale.toggleFullscreen();
    });

    let positions = [
      [550, 260], [810, 260], [1064, 260],
      [550, 510], [810, 510], [1064, 510],
      [550, 760], [810, 760], [1064, 760],
    ]
    this.anims.create({
      key: 'idle',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(1,8) }),
      frameRate: 8,
      repeat: -1
    });
    this.anims.create({
      key: 'sick',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(2,12) }),
      frameRate: 8,
      repeat: -1
    });
    this.anims.create({
      key: 'eat',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(4,11) }),
      frameRate: 8,
      repeat: -1
    });
    this.anims.create({
      key: 'happy',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(3,12) }),
      frameRate: 8,
      repeat: 0
    });
    this.anims.create({
      key: 'laugh',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(5,13) }),
      frameRate: 8,
      repeat: 0
    });

    this.anims.create({
      key: 'laugh',
      frames: this.anims.generateFrameNumbers('char', { frames: this.genFrame(5,13) }),
      frameRate: 8,
      repeat: 0
    });


    this.anims.create({
      key: 'hit',
      frames: this.anims.generateFrameNumbers('bang_hit', { frames: this.genFrame(1,11) }),
      frameRate: 16,
      repeat: 0
    });
    this.anims.create({
      key: 'miss',
      frames: this.anims.generateFrameNumbers('bang_miss', { frames: this.genFrame(1,2) }),
      frameRate: 8,
      repeat: 0
    });

    this.chars = []
    for(var i=0;i<9;i++){
      this.addCharacter(i,positions[i][0],positions[i][1])
    }

    this.timedEvent = this.time.delayedCall(this.timer, this.timeOut, [], this);
    this.timedText = this.add.text(550, 53, 'Timer', { fontFamily: 'WF Prompt', fontSize: 30, color: '#000000' });

    this.scoreTitleText = new TextButton(this, 1150, 25, 320,90, 'คะแนน: 0', null, null, null);
    this.add.existing(this.scoreTitleText)
  }

  addCharacter(i,x,y) {
    this.chars[i] = this.add.sprite(x, y, 'char')
    this.chars[i].alpha = 0
    this.resetChar(i)

    this.chars[i].setInteractive({ useHandCursor: true }).on('pointerdown',(pointer)=>{
      if(this.chars[i].inactive)return;
      let hit = false;
      let bang = null;
      if( this.chars[i].anims.currentAnim.key === 'eat') {
        hit = true
        this.score--
        this.chars[i].play('laugh')
        bang = this.add.sprite(pointer.x, pointer.y, 'bang_miss')
        bang.play('miss')
        this.audio_wrong.play()
        this.audio_wrong_shout.play()
      } else if (this.chars[i].anims.currentAnim.key === 'sick') {
        hit = true
        this.score++
        this.chars[i].play('happy')
        bang = this.add.sprite(pointer.x, pointer.y, 'bang_hit')
        bang.play('hit')
        this.audio_correct.play()
        this.audio_correct_shout.play()
      }
      if(hit) {
        bang.once('animationcomplete',()=>{bang.destroy()});
        this.chars[i].clicked = true
        //this.scoreText.setText(this.score)
        this.scoreTitleText.setText('คะแนน: '+this.score)
        this.chars[i].once('animationcomplete',()=>{this.hideChar(i)})
      }
    });
  }

  hideChar(i) {
    this.chars[i].inactive = true
    this.tweens.add({
      targets: this.chars[i],
      alpha: 0,
      duration: 300,
      ease: 'Power2',
      onComplete:  ()=> {this.resetChar(i)},
    }, this);
    
  }

  resetChar(i) {
    let action = ['sick','eat'];
    this.time.addEvent({
      delay: Math.floor((Math.random() * 10000)+300),
      callback: () => {
        this.chars[i].inactive = false
        this.chars[i].clicked = false
        let a = Math.floor((Math.random() * action.length))
        //char.alpha = true
        this.chars[i].play(action[a])
        this.tweens.add({
          targets: this.chars[i],
          alpha: 1,
          duration: 300,
          ease: 'Power2',
          onComplete:  ()=> {
            this.time.addEvent({
              delay: 1500,
              callback: () => {
                if(!this.chars[i].clicked) {
                  this.hideChar(i)
                }
              }
            })
          },
        }, this);
      }
    })
  }

  timeOut () {

  }

  genFrame(row,count) {
    let result = []
    let from = ((row-1)*13) 
    let to = from+(count-1)
    for(var i=from;i<=to;i++) {
      result.push(i)
    }
    return result
  }

  update () {
    let progress = this.timedEvent.getProgress()
    let timeLeft = ((this.timer - this.timedEvent.delay*progress)/1000).toFixed(0);
    let width = 1000-Math.round(progress*1000)+30
    this.bar.clear()
    if(progress < 0.8) {
      this.bar.fillStyle(0x79C962, 1)
    } else {
      this.bar.fillStyle(0xFC6801, 1)
    }
    this.bar.fillRoundedRect(55, 55, width, 30, 15);
    if(progress === 1) {
      this.gameEnded()
    }
    let seconds = Math.floor(timeLeft % 60);
    let minutes = Math.floor(timeLeft / 60);
    let timeFormatted = (this.zeroPad(minutes,2)+":"+this.zeroPad(seconds,2));
    this.timedText.setText(timeFormatted);
  }

  gameEnded() {
    this.scene.pause();
    this.audio_win.play()
    this.bg_audio.pause()
    setTimeout(()=>{
      this.ended = true
      this.scene.start('EndScene',{score:this.score, isWin:false})
    },600)
  }

  init(data) {
    this.ended = false
    this.timer = 30000
    this.score = 0
  }

  zeroPad(number,size){
    var stringNumber = String(number);
    while(stringNumber.length < (size || 2)){
      stringNumber = "0" + stringNumber;
    }
    return stringNumber;
  }
}
